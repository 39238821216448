import React, { useState } from 'react'
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import RegistrationForm from './registration-form'
import {H, StatusContainer} from './elements'

const url = "https://fontanus.us13.list-manage.com/subscribe/post?u=009ef911b69f746f5a8e02213&amp;id=4ed6da99c2";

const getAjaxUrl = url => url.replace("/post?", "/post-json?");

function subscribe(values, callback) {
  const params = toQueryString(values);
  const fixedurl = getAjaxUrl(url) + "&" + params;
  return new Promise((resolve, reject) => {
    jsonp(
      fixedurl,
      {
        param: "c"
      },
      callback
    )
  })
}

function MyForm() {
  const [state, setState] = useState('form')
  return (
    <div>
      <H>Regisztráció</H>
      {state === 'form' 
      ? <RegistrationForm subscribe={subscribe} onSuccess={() => setState('success')} /> 
      : <StatusContainer>
        Az online játék jelenleg fejlesztés alatt áll. <br />
        E-mailben értesíteni fogunk az online Castle of Mind indulásáról.<br/><br/>
        Köszönjük a regisztrációt!</StatusContainer>}
    </div>
  )
}

export default MyForm
