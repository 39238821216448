import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from './theme'
import Form from './form'
import Page from './page'
import ComBg from '../../assets/com_bg.jpg'

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
  }
  body {
    background-image: url(${ComBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #191919;
    background-position: center center;
  }
  #app {
    height: 100%;
  }
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Page>
        <GlobalStyle />
        <Form></Form>
      </Page>
    </ThemeProvider>)
}

var mountNode = document.getElementById("app");
ReactDOM.render(<App/>, mountNode);