// @flow
import * as React from 'react';
import styled from 'styled-components'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => props.checked ? 'salmon' : 'papayawhip'}
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 3%;
`

type CheckboxProps = {
  children: React.Node, 
  onChange: () => void,
  props: {
    checked: Boolean,
    className: string,
    onBlur: () => void
  }
}

const Checkbox = (props: { className?: String, checked: boolean, props: any}) => {
  const { className, checked, ...checkboxProps } = props
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...checkboxProps} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  )
}

const LabelWithMargin = styled.label`
  display: block;
  margin-bottom: 2%;
  padding: 3%;
`

function CheckboxWithState({children, onChange, ...props}: CheckboxProps) {
  const [checked, setChecked] = React.useState(false)
  const onChangeWithState = (args) => {
    onChange(args)
    setChecked(!checked)
  }
  return (<LabelWithMargin>
    <Checkbox {...props} checked={checked} onChange={onChangeWithState} />
    {children}
  </LabelWithMargin>)
}

export default CheckboxWithState