import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Card } from 'rebass'
import Logo from '../../assets/logo_gradient.png'

const Wrapper = styled(Flex)`
  height: 100%;
`

function Page({children}) {
  return (<Wrapper 
    justifyContent="center" 
    alignItems="center">
    <Card
      p={4}
      py={6}
      backgroundImage={`url(${Logo})`}
      backgroundRepeat='no-repeat'
      backgroundPosition='center 10px'
      borderRadius={8}
      color='white'
      bg='darkgray'
    >
  {children}
    </Card>
  </Wrapper>)
}

export default Page