import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { P, Input, Button, StatusContainer, Checkbox } from './elements'
import * as Yup from 'yup'

const SignupSchema = Yup.object().shape({
  FNAME: Yup.string()
    .min(2, 'Legalább 3 karakter')
    .max(50, 'Maximum 50 karakter')
    .required('Kötelező'),
  TOKEN: Yup.string()
    .matches(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/, { message: 'Érvénytelen token' })
    .required('Kötelező'),
  EMAIL: Yup.string()
    .email('Érvénytelen e-mailcím')
    .required('Kötelező'),
  gdpr: Yup.boolean()
    .oneOf([true], 'Kötelező')
    .required('Kötelező')
})

function RegistrationForm ({ subscribe, onSuccess }) {
  return (
    <Formik
      initialValues={{ FNAME: '', EMAIL: '', TOKEN: '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        const promise = new Promise((resolve, reject) => {
          subscribe(values, (err, data) => {
            actions.setSubmitting(false)
            if (err) {
              actions.setStatus({
                type: 'error',
                msg: 'Hiba az adatok feldolgozása közben. Kérlek, próbáld meg később!'
              })
              reject(err)
            } else if (data.result !== 'success') {
              actions.setStatus({
                type: 'error',
                msg: data.msg
              })
              reject(new Error(data.msg))
            } else {
              actions.setStatus({
                type: 'success',
                msg: data.msg
              })
              onSuccess()
              resolve(data.msg)
            }
          })
        })
        return promise
      }}
    >
      {({
        isSubmitting,
        status,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => (
        <Form>
          {status && status.msg && <StatusContainer variant={status.type}>{status.msg}</StatusContainer>}
          {errors.FNAME && touched.FNAME ? (
            <StatusContainer variant='error'>{errors.FNAME}</StatusContainer>
          ) : null
          }
          <Input type='text'
            hasError={errors.FNAME}
            name='FNAME'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='Becenév' />
          {errors.EMAIL && touched.EMAIL ? (
            <StatusContainer variant='error'>{errors.EMAIL}</StatusContainer>
          ) : null
          }
          <Input type='email'
            hasError={errors.EMAIL}
            name='EMAIL'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='E-mail' />
          {errors.TOKEN && touched.TOKEN ? (
            <StatusContainer variant='error'>{errors.TOKEN}</StatusContainer>
          ) : null
          }
          <Input type='text'
            hasError={errors.TOKEN}
            name='TOKEN'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='Token' />
          {errors.gdpr ? (
            <StatusContainer variant='error'>{errors.gdpr}</StatusContainer>
          ) : null
          }
          <Checkbox
            name='gdpr'
            value={true}
            onChange={handleChange}
            onBlur={handleBlur}
          ><span>Elfogadom az adatkezelési tájékoztatóban foglaltakat</span></Checkbox>
          <Button type='submit' disabled={isSubmitting}>
              Regisztrálok
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default RegistrationForm
